import React, { Suspense, lazy, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroArea from "@components/introarea/layout-1";
import BoxSection from "@components/BoxSection/layout-one/layout-5";
import TabsSection from "@components/bluetabs-section/layout-1";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import FaqArea from "@containers/faq/layout-03";
import ContactArea from "@containers/contact-us";
import UseScroll from "../../containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const TranscreationAdaptationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 20) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo title="Adaptation and Transcreation Services by Andovar" description="Andovar’s adaptation and transcreation services expand your brand message to any audience in any international market. Contact us for more information." />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader
          data={content["technical-translation-header-section"]}
          pageContext={content["technical-translation-meta-description"]}
        />
        <IntroArea data={content["technical-adaptation-intro"]} />
        {
          showRestComponents && <>
            <BoxSection layout={5} data={content["why-use-andovar"]} />
            <TabsSection layout={2} data={content["adapt-talent-body"]} />
            <CaseStudy data={caseStudiesData} />
            <FaqArea data={content["technical-translation-faq-body"]} />
            <ContactArea Layout={2} />
          </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query transcreationAdaptationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "transcreation-adaptation" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

TranscreationAdaptationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default TranscreationAdaptationPage;
